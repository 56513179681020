import React, { Component } from 'react'
import './TermsCondition.css';
class GeneralConditions extends Component {
  render() {
    return (
      <div className="main-section  main-body-bg">
        <div className="term-and-condition">
          <div className="width-1200">
            <div className="row inner-terms-container">
              <div className="col-md-12">
                <h1 className="h1-basic01 tc-head">Allmänna villkor för Kreditz kontoinformationstjänster
                </h1>
              </div>
              <div className="col-md-12">
                <h3>V 1.0 – 2024-11-01</h3><br />
                <p>Dessa allmänna villkor (<b>“Villkoren”</b>) gäller för åtkomst till och användning av Tjänsterna (enligt definition i punkt 2 nedan) som tillhandahålls av Kreditz AB, organisationsnummer 559148-2400, (<b>“Kreditz”</b>, <b>“vi”</b>, <b>“oss”</b> eller <b>“våra”</b>).
                  Kreditz, ett svenskt aktiebolag med säte i Stockholm, är ett registrerat betalningsinstitut som tillhandahåller kontoinformationstjänster vilket möjliggör tillhandahållandet av kontoinformationstjänster. Dessa tjänster tillhandahålls under tillsyn av Finansinspektionen. Kontaktuppgifter finns i avsnitt 16, och ytterligare information om Kreditz finns på vår webbplats www.kreditz.com  (<b>”Webbplatsen”</b>).<br></br>
                </p>
                <p>Villkoren, tillsammans med andra villkor som kan godkännas vid användning av Tjänsterna, utgör ett avtal (<b>“Avtalet”</b>) mellan Kreditz och dig som användare av tjänsterna, eller den juridiska person du representerar (”användaren” eller ”du”). Observera särskilt bestämmelserna i punkt 6.1 angående ansvar för betalkonton och andra konton som du innehar tillsammans med någon annan, eller som tillhör en juridisk person.
                  Normalt upphör Avtalet att gälla så snart den Tjänst du har beställt har levererats. För mer information om avtalstid och uppsägning, se punkt 10. Om det däremot finns ett ramavtal mellan dig och Kreditz för löpande inhämtning av kontoinformation, gäller överenskommelsen tills den sägs upp av antingen dig eller Kreditz. Mer information om ramavtal finns i punkt 4.</p>
                <p>Villkoren, som är en viktig del av Avtalet, finns också tillgängliga via Webbplatsen.
                  Läs Villkoren noggrant innan du godkänner dem och ingår denna överenskommelse. Om du inte accepterar samtliga bestämmelser i Villkoren, har du inte rätt att använda Tjänsterna.Har du frågor om Villkoren, är du välkommen att kontakta oss som leverantör via <a href="mailto:info@kreditz.com">info@kreditz.com</a>. Fullständig kontaktinformation finns i punkt 16.
                </p>
                <p>Nedan finner du en sammanfattade förhandsinformation i tabellen nedan. Det är dock viktigt att du läser igenom hela Villkoren.
                </p><br></br>
                <div className="col-md-12 text-table">
                  <p>Företagets kontaktinformation</p>
                  <p>Kreditz AB, Vendevägen 87, 182 32 Danderyd, Sverige. Email: <a href="mailto:info@kreditz.com">info@kreditz.com</a>.</p>
                </div>
                <div className="col-md-12 text-table">
                  <p>Beskrivning av Tjänsternas huvudsakliga egenskaper</p>
                  <p>Företagets kontoinformationstjänst ger dig som kontoinnehavare möjlighet att begära att information från ditt konto hämtas och görs tillgänglig via en partner till Företaget. Denna information används för att analysera transaktionshistorik i syfte att bedöma kreditvärdighet, olika typer av risker, penningtvättskontroll samt beräkningar för disponibel inkomst, enligt dina instruktioner. Se punkt 1.5 nedan för ytterligare information om Partnertjänsten.</p>
                </div>
                <div className="col-md-12 text-table">
                  <p>Information som ska tillhandahållas av användaren för ett korrekt genomförande av tjänsten</p>
                  <p>För att kunna använda tjänsterna behöver du tillhandahålla information enligt de instruktioner som Kreditz ger dig.</p>
                </div>
                <div className="col-md-12 text-table">
                  <p>Form och tillvägagångssätt för att ge samtycke till genomförande av Tjänsterna och återkallande av sådant samtycke</p>
                  <p>Om du har begärt en engångshämtning av kontoinformation gäller ditt samtycke endast för den aktuella inhämtningen, och något återkallande av samtycke behövs därför inte. Om kontoinformationstjänsten tillhandahålls löpande via ett ramavtal, kan du när som helst återkalla ditt samtycke genom att säga upp ramavtalet. Detta görs genom att kontakta oss på info@kreditz.com.</p>
                </div>
                <div className="col-md-12 text-table">
                  <p>Tjänstens maximala genomförandetid</p>
                  <p>Tjänsterna utförs så snart det är möjligt.</p>
                </div>
                <div className="col-md-12 text-table">
                  <p>Kreditz samtliga avgifter som erläggs användaren</p>
                  <p>Företaget erbjuder tjänsterna till dig utan kostnad. Det är Kreditz partner, som tillhandahåller tjänsten till dig, som står för kostnaden.</p>
                </div>
                <div className="col-md-12 text-table">
                  <p>Kommunikationskanaler samt tekniska krav för användarens utrustning som överenskommet mellan parterna för överförande av information eller notifikationer</p>
                  <p>All kommunikation om Tjänsterna sker online, via e-post eller andra digitala kanaler. Du behöver internetuppkoppling för att använda tjänsterna, som är tillgängliga via en webbläsare och inte kräver någon särskild programvara.</p>
                </div>
                <div className="col-md-12 text-table">
                  <p>Användarens rätt att erhålla villkoren på papper eller annat varaktigt medium</p>
                  <p>Villkoren kan på begäran tillhandahållas på varaktigt medium.</p>
                </div>
                <div className="col-md-12 text-table">
                  <p>Beskrivning av sätt för att notifiera användaren om obehörig användning eller säkerhetsrisker</p>
                  <p>Meddelanden kommer att skickas elektroniskt, med tillämpning av lämpliga säkerhetsåtgärder.</p>
                </div>
                <div className="col-md-12 text-table">
                  <p>Villkor för att blockera en användare</p>
                  <p>Kreditz kan när som helst blockera Tjänsterna om det finns risk för osäker användning eller misstanke om obehörigt nyttjande. Detta gäller även vid misstankar om bedrägeri eller annan olaglig verksamhet.</p>
                </div>
                <div className="col-md-12 text-table">
                  <p>Användarens skyldigheter för obehörig användning</p>
                  <p>Om du misstänker att en kontoinformationshämtning har skett utan ditt godkännande eller har genomförts felaktigt, är du skyldig att omedelbart, efter att ha blivit medveten om detta, rapportera det till banken där ditt konto finns, enligt deras instruktioner.</p>
                </div>
                <div className="col-md-12 text-table">
                  <p>Information om att villkorsändringar anses accepterade om inte användaren informerar Kreditz om annat</p>
                  <p>Om ett ramavtal föreligger mellan dig och Kreditz, kommer ändringar i Villkoren att träda i kraft tidigast 2 månader efter att du informerats om dem. Om du inte accepterar de ändrade villkoren kan du när som helst avsluta avtalet genom att kontakta oss på info@kreditz.com. </p>
                </div>
                <div className="col-md-12 text-table">
                  <p>Avtalets giltighetstid</p>
                  <p>Avtalet upphör normalt att gälla så snart den Tjänst du har begärt har utförts. Om du har ingått ett ramavtal gäller detta i 365 dagar från den senaste tjänsteutförandet på din begäran. Trots detta kan du när som helst avsluta avtalet genom att kontakta oss på info@kreditz.com.</p>
                </div>
                <div className="col-md-12 text-table">
                  <p>Rätt att säga upp avtalet</p>
                  <p>Avtalet avslutas normalt så snart den av dig begärda Tjänsten är utförd. Om du har ingått ett Ramavtal löper detta under en period av 365 dagar sedan den dag då Tjänsterna senast utfördes på din begäran. Dock kan du utan hinder av detta när som helst säga upp Avtalet genom att kontakta oss via info@kreditz.com.</p>
                </div>
                <div className="col-md-12 text-table">
                  <p>Information om tillämplig lag</p>
                  <p>Avtalet och Villkoren ska regleras av och tolkas i enlighet med svensk lag, utan hänsyn till tillämpning av lagvalsregler, och med förbehåll för tvingande bestämmelser enligt lagen som gäller i det land du är bosatt. I den utsträckning svensk lag står i strid med lagen som gäller i det land där du är bosatt, ska svensk lag gälla i maximalt tillåtna utsträckning. Om det inte strider mot lag i landet du är bosatt ska tvist avgöras av svensk domstol.</p>
                </div>
                <div className="col-md-12 text-table">
                  <p>Klagomålshantering utanför domstol</p>
                  <p>För användare bosatta i Sverige – Allmänna reklamationsnämnden. För användare bosatta utanför Sverige, vänligen se <a href="https://ec.europa.eu/info/business-economy-euro/banking-and-finance/consumer-finance-and-payments/retail-financial-services/financial-dispute-resolution-network-fin-net/make-complaint-about-financial-service-provider-another-eea-country_en för vägledning">https://ec.europa.eu/info/business-economy-euro/banking-and-finance/consumer-finance-and-payments/retail-financial-services/financial-dispute-resolution-network-fin-net/make-complaint-about-financial-service-provider-another-eea-country_en för vägledning</a>.</p>
                </div>
              </div><br />
              <div className="col-md-12 general-text">
                <h3>1. ALMÄNT</h3><br />
                <p>1. Genom att använda tjänsterna ingår du Avtalet och bekräftar att du har läst, förstått och accepterat dessa Villkor. För att använda Tjänsterna krävs att du är minst 18 år gammal och/eller har behörighet att ingå Avtalet samt att du accepterar dessa Villkor.
                </p>
                <p>2. Genom att använda Tjänsterna ger du Kreditz tillstånd att få åtkomst till dina inloggningsuppgifter hos relevanta banker, Betaltjänstleverantörer eller andra Verksamhetsutövare (kallade <b>”Externa Inloggningsuppgifter”</b>) enligt instruktionerna för varje Tjänst. Genom detta ger du ditt uttryckliga samtycke och uppdrag till Kreditz att använda dina Externa Inloggningsuppgifter för att utföra Tjänsterna i enlighet med punkterna <b>Error! Reference source not found.</b> och 3 nedan.
                </p>
                <p>3. Du är ansvarig för att du har behörighet att använda de Externa Inloggningsuppgifterna och för att begära tillhandahållande av Tjänsterna.
                </p>
                <p>4. Tjänsterna levereras till dig via våra samarbetspartners (<b>“Partner”</b>) som erbjuder en eller flera egna tjänster (<b>“Partnertjänst”</b>). Observera att Kreditz endast ansvarar för tillhandahållandet av våra Tjänster till dig enligt dessa Villkor. Partnertjänster tillhandahålls av respektive Partner i enlighet med de Villkor som gäller för den specifika Partnertjänsten och omfattas inte av detta Avtal. Mer information om Tjänsternas funktion finns nedan, och information om Partnertjänster kan erhållas från respektive Partner. Du samtycker till att all kommunikation med Kreditz angående Tjänsterna sker online, via e-post eller andra digitala medier. Vid behov kan du begära att få Villkoren på ett varaktigt medium. Vårt primära kommunikationsspråk är svenska, eller engelska när Tjänsterna erbjuds utanför Sverige.
                </p>
                <p>5. För att kunna använda Tjänsterna behöver du en internetuppkoppling. Tjänsterna är åtkomliga via en webbläsare och kräver ingen särskild programvara.
                </p>
              </div><br />
              <div className="col-md-12 general-text">
                <h3>2. TJÄNSTER</h3><br />
                <p>1.  Villkoren gäller när vi tillhandahåller kontoinformationstjänster (gemensamt benämnda <b>”Tjänsterna”</b>) till dig. Bestämmelser om kontoinformationstjänster finns i punkt 3.
                </p>
              </div><br />
              <div className="col-md-12 general-text">
                <h3>3. KONTOINFORMATIONSTJÄNST</h3><br />
                <p>1. Genom att använda Kreditz kontoinformationstjänst (<b>”Kontoinformationstjänsten”</b>) kan du, som kontoinnehavare eller representant för den juridiska personen som äger kontot, snabbt och säkert identifiera dig och ange att den insamlade informationen från dessa konton (<b>”Informationen”</b>) ska göras tillgänglig för Partners i samband med deras leverans av Partnertjänster. Kontoinformationstjänsten kräver alltid ditt uttryckliga samtycke för att vi ska kunna utföra tjänsten och samla in relevant information, samt göra Informationen tillgänglig för den Partner som du anvisat.
                </p>
                <p>2. Kreditz kan samla in information från betalkonton, kredit- och betalkortskonton, sparkonton, investeringssparkonton med mera, via de API som tillhandahålls av ditt kontoförande institut. För att Kontoinformationstjänsten ska kunna genomföras krävs att kontot är tillgängligt online.
                </p>
                <p>3. Kontoinformationstjänsten tillhandahålls inom ramen för en Partnertjänst. Innan tjänsten startas visas en lista över tillgängliga tredje parter, banker och andra aktörer (<b>”Informationskällan”</b>) från vilka Informationen kan inhämtas. Du väljer själv Informationskällan från denna lista. I nästa steg informeras du om att Kontoinformationstjänsten kommer att utföras av Kreditz. För att påbörja tjänsten måste du godkänna dessa Villkor och ge ditt uttryckliga medgivande. Genom att godkänna villkoren och gå vidare i tjänsten lämnar du ditt samtycke till tjänstens genomförande. Vid samma tillfälle presenteras information om Kreditz hantering av dina personuppgifter. När vi har tagit emot din begäran om att utföra Kontoinformationstjänsten, identifierar du dig gentemot Informationskällan enligt deras instruktioner med hjälp av Externa Inloggningsuppgifter. Efter att du har gett ditt samtycke kan det inte återkallas. Därefter skickas en omedelbar förfrågan till Informationskällan om att hämta Informationen, och Kreditz inhämtar denna på ditt uppdrag. När Informationen är inhämtad görs den tillgänglig för den Partner du valt, och Kontoinformationstjänsten är därmed slutförd.
                </p>
                <p>4. Om Kontoinformationstjänsten tillhandahålls löpande under ett Ramavtal (se punkt 4 nedan), kommer Kreditz, efter att ha fått ditt uttryckliga medgivande och genomfört en första identifiering gentemot Informationskällan, att kunna uppdatera Informationen automatiskt från Informationskällan under den tid som Informationskällan tillåter. Denna period kan variera. I vissa fall kan dock en uppdatering av Informationen kräva ett nytt samtycke och att du identifierar dig igen för varje uppdatering hos Informationskällan.
                </p>
                <p>5. Tiden för att genomföra datainhämtningen kan variera beroende på hur lång tid identifieringsprocessen gentemot Informationskällan tar och mängden information som ska hämtas, men inhämtningen sker så snart som möjligt efter att du har gett ditt medgivande.
                </p>
              </div><br />
              <div className="col-md-12 general-text">
                <h3>3.  RAMAVTAL</h3><br />
                <p>1. Som framgår av punkt 13 i Villkoren upphör Avtalet normalt att gälla efter att vi har utfört den Tjänst du har beställt. Vid tillhandahållande av Kontoinformationstjänster kan en viss funktionalitet i en Partnertjänst kräva att Information från ditt konto hämtas återkommande, där du även ger samtycke till att denna Information tillgängliggörs för Partnern flera gånger under en specifik tidsperiod.
                </p>
                <p>2. I de fall där Partnertjänsten kräver det, ger du genom att acceptera Villkoren ditt samtycke till återkommande hämtningar av Information enligt punkterna 3.3 och 4.4 ovan. Med detta samtycke kommer Kreditz att, inom ramen för Kontoinformationstjänsten, uppdatera Informationen löpande med den frekvens och under den tidsperiod som du har gett ditt uttryckliga medgivande till, i den omfattning som tillåts av Informationskällan. Om det är tillåtet av Informationskällan fortsätter Kreditz att uppdatera Informationen enligt den metod som används av Informationskällan.
                </p>
                <p>3. Med stöd av ditt medgivande kommer vi då inom ramen för Kontinformationtjänsten att uppdatera Informationen upp till 4 gånger per dag under en period om högst 90 dagar från ditt medgivande. Efter denna tidsperiod har löpt ut krävs ett nytt medgivande från dig för en fortsatt löpande uppdatering. Beroende på den metod som tillämpas av din bank kan ett nytt medgivande i stället krävas för varje hämtning.</p>
                <p>4. Om och när du samtycker till återkommande hämtningar av Information enligt punkt 4.1 ovan innebär det att Kontoinformationstjänsten tillhandhålls över tid, till skillnad mot vad som gäller när du begär enstaka hämtningar av Information. I de fall du har lämnat ditt medgivande till återkommande hämtningar gäller därför Avtalet tillsvidare och till dess att du eller vi har sagt upp Avtalet. Ett sådant tillsvidareavtal benämns i Villkoren <b>”Ramavtal”</b>. Bestämmelser om avtalstid och uppsägning hittar du i punkt 10 nedan.</p>
              </div><br />
              <div className="col-md-12 general-text">
                <h3>5. AVGIFTER</h3><br />
                <p>1. Kreditz tillhandahåller Tjänsterna kostnadsfritt till dig. Trafikavgifter till din mobiloperatör eller internetleverantör kan tillkomma. För Partnertjänster gäller respektive Partners priser och användarvillkor</p>
              </div><br />
              <div className="col-md-12 general-text">
                <h3>6. MER OM DITT ANSVAR FÖR ANVÄNDNING AV TJÄNSTERNA</h3><br />
                <p>1. Du är ansvarig för att du har behörighet att använda de Externa Inloggningsuppgifterna och att beställa respektive Tjänst. Detta ansvar gäller även för betalkonton och andra konton som du delar med någon annan eller som tillhör en juridisk person (exempelvis ett företag eller annan organisation).</p>
                <p>2. Utöver ovanstående är du även ansvarig för och garanterar att:
                  <ul className='responsibility-text'>
                    <li>du använder Tjänsterna i enlighet med Villkoren samt gällande lagar och
                      regler.</li>
                    <li>du skyddar dina Externa Inloggningsuppgifter mot obehörig åtkomst.</li>
                    <li>du skyddar din tekniska utrustning mot obehörigt användande, exempelvis genom telefonlåskod eller biometriska kännetecken som fingeravtryck.</li>
                    <li>du omedelbart informerar Kreditz vid misstanke om eller vetskap om obehörig användning av Tjänsterna eller din tekniska utrustning.</li>
                    <li>informationen du tillhandahåller i Tjänsterna är korrekt, uppdaterad och fullständig.</li>
                    <li>informationen du lämnar i Tjänsterna inte kränker någon annans rättigheter eller strider mot lag eller någon annans rätt.</li>
                    <li>du avstår från att kopiera, modifiera, distribuera eller publicera innehåll som tillhandahålls av Kreditz inom Tjänsterna, och du missbrukar inte Tjänsterna eller använder dem för andra syften än de är avsedda för.</li>
                    <li>du inte försöker kringgå säkerhetsfunktioner eller andra begränsningar som gäller för Tjänsterna.</li>
                  </ul>
                </p>
              </div><br />
              <div className="col-md-12 general-text">
                <h3>7. TILLGÄNGLIGHET OCH VÅRT ANSVAR</h3><br />
                <p>1. Tjänsterna är vanligtvis tillgängliga dygnet runt, året om. Vi kan dock inte garantera att Tjänsterna alltid är felfria eller utan avbrott. Vi förbehåller oss rätten att ändra verksamhetsmetoder, tekniska specifikationer och att stänga ner Tjänsterna för underhåll eller liknande aktiviteter. Vårt mål är att Tjänsterna fungerar korrekt och att all information i Tjänsterna är så korrekt och uppdaterad som möjligt. Kreditz kan dock inte garantera tillförlitligheten eller exaktheten av informationen som visas i Tjänsterna. Vi kan inte hållas ansvariga om Tjänsterna är otillgängliga eller om någon information eller funktion inte fungerar som förväntat, förutom i de fall där Kreditz ansvar regleras av lag</p>
                <p>2. Tjänsternas funktionalitet kan påverkas av tjänster som tillhandahålls av tredje parter (såsom mobiloperatörer och internetleverantörer). Vi ansvarar inte för dessa tjänster som erbjuds av tredje parter.</p>
                <p>3. Kreditz ansvarar inte för ekonomiska beslut eller andra beslut som du fattar baserat på din användning av Tjänsterna, eller för tjänster eller varor som du väljer att köpa från oberoende tredje parter eller Kreditz samarbetspartners, inklusive Partners</p>
                <p>4. Förutom vad som krävs enligt tvingande lag, är Kreditz under inga omständigheter ansvarigt gentemot dig eller tredje part för direkta eller indirekta förluster eller skador som uppstått i samband med tillhandahållandet av Tjänsterna.</p>
                <p>5. Om inget annat anges i tvingande lag, ska Kreditz totala ansvar för skador relaterade till din användning av Tjänsterna aldrig överstiga 500 kronor</p>
              </div><br />
              <div className="col-md-12 general-text">
                <h3>8. PERSONUPPGIFTSBEHANDLING</h3><br />
                <p>1. Genom att tillhandahålla Tjänsterna behandlar Kreditz uppgifter om dig. Du hittar information om vår hantering av dina personuppgifter på Webbplatsen.</p>
              </div><br />
              <div className="col-md-12 general-text">
                <h3>9. BROTT MOT VILLKOREN M.M.</h3><br />
                <p>1. Om du bryter mot Villkoren eller orsakar skada för oss har vi rätt att avsluta din åtkomst till Tjänsten.
                </p>
                <p>2. Vi förbehåller oss rätten att när som helst stänga av dig från Tjänsterna om du bryter mot Villkoren eller använder Tjänsterna på ett sätt som kan orsaka skada för Kreditz eller tredje part.</p>
                <p>3. Vid misstänkta säkerhetsrisker kan Kreditz meddela dig på ett sätt som anses lämpligt för situationen. Kreditz förbehåller sig också rätten att när som helst blockera Tjänsterna om det finns risk för osäker användning eller vid misstanke om obehörig användning. Detta gäller även vid misstanke om bedrägeri eller annan olaglig verksamhet.</p>
              </div><br />
              <div className="col-md-12 general-text">
                <h3>10. AVTALSTID OCH UPPSÄGNING</h3><br />
                <p>1. Varje Tjänst tillhandahålls och genomförs omedelbart eller i nära anslutning till
                  din begäran om att Tjänsten ska utföras.</p>
                <p>2. Om du och Kreditz inte har ingått ett Ramavtal (se punkt 4 angående Ramavtal) upphör Avtalet automatiskt att gälla när vi har utfört den Tjänst du beställt.</p>
                <p>3. Om ett Ramavtal har ingåtts mellan dig och Kreditz (se punkt 4 för Ramavtal), gäller Avtalet under en period av 365 dagar från den dag då Tjänsterna senast utfördes på din begäran. Du kan dock när som helst säga upp Avtalet genom att kontakta oss på <a href="mailto:info@kreditz.com">info@kreditz.com</a>.</p>
                <p>4. Avtal som utgör ett Ramavtal kan även sägas upp av Kreditz, med en
                  uppsägningstid på 2 månader</p>
              </div><br />
              <div className="col-md-12 general-text">
                <h3>11. FORCE MAJEURE</h3><br />
                <p>1. Vi ansvarar inte för förseningar eller brister i funktionalitet eller prestanda som är utanför vår rimliga kontroll och som vi inte rimligen kan förutse (force majeure), vilket inkluderar men inte är begränsat till händelser som bränder, översvämningar, explosioner, uppror, krig, orkaner, sabotage, terrorism, vandalism, olyckor, sjukdomsepidemier, myndighetsbeslut, domstolsförelägganden, strejker eller andra liknande händelser. Detta gäller även om Kreditz har gjort rimliga försök att förebygga, undvika, försena eller minska effekterna av sådana händelser</p>
              </div><br />
              <div className="col-md-12 general-text">
                <h3>12. ÄNDRING AV VILLKOREN</h3><br />
                <p>1. Vi förbehåller oss rätten att när som helst ändra Tjänsterna, göra justeringar i Villkoren eller avsluta tillhandahållandet av Tjänsterna. Om du inte accepterar en ändring av Villkoren har du rätt att när som helst säga upp Avtalet. Om du fortsätter att använda Tjänsterna efter att ändringen trätt i kraft anses du ha accepterat de ändrade Villkoren.</p>
                <p>2. Om Avtalet utgör ett Ramavtal (se punkt 4 om Ramavtal) träder ändringar av Villkoren i kraft tidigast två månader efter att du har fått information om dessa ändringar. Om du inte accepterar de ändrade Villkoren kan du när som helst säga upp Avtalet enligt punkt 10</p>
                <p>3. Kreditz har rätt att ändra Ramavtal och Villkoren utan att beakta den två månaders frist som anges i punkt 12.2, om ändringen inte påverkar dina rättigheter som betaltjänstanvändare eller innebär någon väsentlig nackdel för dig. Ändrade Villkor träder i kraft vid den tidpunkt som Kreditz anger, om ändringen inte påverkar dina rättigheter eller innebär en väsentlig nackdel och du inte agerar som konsument. Om du inte accepterar de ändrade Villkoren kan du när som helst säga upp Avtalet enligt punkt 10.</p>
              </div><br />
              <div className="col-md-12 general-text">
                <h3>13. ÖVRIGT</h3><br />
                <p>1. Kreditz har rätt att överföra sina rättigheter och skyldigheter enligt Avtalet. Dessutom har Kreditz rätt att använda underleverantörer för att uppfylla sina skyldigheter.</p>
                <p>2. Villkoren är tillgängliga på Webbplatsen. Du har också rätt att få en kopia av dessa Villkor på begäran och kan kontakta oss med din förfrågan via <a href="mailto:info@kreditz.com">info@kreditz.com</a>.</p>
                <p>3. Lagstadgad information om Tjänsterna tillhandahålls enligt gällande bestämmelser på Webbplatsen eller via info@kreditz.com när du efterfrågar den.</p>
              </div>
              <div className="col-md-12 general-text">
                <h3>14. TILLÄMPLIG LAG OCH TVISTLÖSNING</h3><br />
                <p>1. Avtalet och Villkoren ska styras av och tolkas enligt svensk lag, utan att beakta lagvalsregler, och med förbehåll för tvingande bestämmelser i det land där du är bosatt. I den mån svensk lag strider mot lagen i det land där du bor, ska svensk lag tillämpas i så stor utsträckning som är tillåten. Om detta inte strider mot lagen i ditt hemland, ska eventuella tvister avgöras i svensk domstol.
                </p>
              </div>
              <div className="col-md-12 general-text">
                <h3>15. KLAGOMÅL OCH TILLSYN</h3><br />
                <p>1. Vi bryr oss om våra kunder och är angelägna om att besvara dina frågor eller få kännedom om du är missnöjd med våra tjänster. Vårt mål är att hantera alla synpunkter så snabbt som möjligt. För att kontakta vår kundtjänst, vänligen använd info@kreditz.com (ange “Klagomål” i ämnesfältet). Skriftliga klagomål kan även skickas till:<br></br>Kreditz AB<br></br>Att: Klagomålsansvarig<br></br>Vendevägen 87, 182 32 Danderyd</p>
                <p>2. Vi behandlar ditt klagomål omgående och strävar efter att hantera klagomål inom 15 arbetsdagar från det datum vi tog emot det. Om vi på grund av särskilda skäl inte kan ge dig ett svar inom 15 arbetsdagar, kommer vi att meddela dig om orsaken och kan då förlänga svarstiden till upp till 35 dagar. Om du inte får vårt slutliga svar eller är missnöjd med det, har du rätt att eskalera ditt klagomål till din lokala ombudsman i ditt hemland och följa de lokala processerna för registrering utanför domstol. För boende i Sverige kan klagomål registreras hos Allmänna reklamationsnämnden via arn@arn.se. För andra länder än Sverige finns ytterligare information på Nätverket för lösning av finansiella tvister, FIN-Net: <a href="https://finance.ec.europa.eu/consumer-finance-and-payments/retail-financial-services/financial-dispute-resolution-network-fin-net/make-complaint-about-financial-service-provider-another-eea-country_en">https://finance.ec.europa.eu/consumer-finance-and-payments/retail-financial-services/financial-dispute-resolution-network-fin-net/make-complaint-about-financial-service-provider-another-eea-country_en</a>. Du bör göra detta inom sex månader från vårt slutliga svar.</p>
                <p>3. Kreditz är ett registrerat betalningsinstitut under Finansinspektionens tillsyn, vilket framgår av företagsregistret på <a href="www.fi.se">www.fi.se</a> och kan bekräftas genom kontakt med:<br></br><b>FINANSINSPEKTIONEN</b><br></br><b>Box 7826</b><br></br><b>103 97 Stockholm</b><br></br><b>+46 8 408 980 00</b></p>
              </div>
              <div className="col-md-12 general-text">
                <h3>16. KONTAKTINFORMATION</h3><br />
                <p>1. Du når oss alltid enligt följande:</p>
                <p><b>Kreditz AB (organisationsnummer 559148-2400)</b><br></br>
                  <b>Vendevägen 87, 182 32 Danderyd</b><br></br>
                  <b>E-post: info@kreditz.com</b>
                </p><br></br>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default GeneralConditions;